import React from 'react';

import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import LockIcon from '@mui/icons-material/Lock';
import ChatIcon from '@mui/icons-material/Chat';
import BuildIcon from '@mui/icons-material/Build';
import HelpIcon from '@mui/icons-material/Help';
import SchoolIcon from '@mui/icons-material/School'
import GitHubIcon from '@mui/icons-material/GitHub';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import WebIcon from '@mui/icons-material/Web';
import CloudIcon from '@mui/icons-material/Cloud';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Theme } from '@mui/material/styles';

export const developerPortalLinks = (theme: Theme) => [
  {
    url: 'https://novonordisk.sharepoint.com/sites/DevX',
    label: 'DevX Scalability Program',
    icon: <HomeIcon sx={{ color: theme.palette.primary.main }} />
  },
  {
    url: 'https://backstage.io/docs/overview/what-is-backstage/',
    label: 'Backstage Official TechDocs',
    icon: <DescriptionIcon sx={{ color: theme.palette.primary.main }} />
  },
  {
    url: 'https://github.com/innersource-nn',
    label: 'GitHub Inner Source',
    icon: <GitHubIcon sx={{ color: theme.palette.primary.main }} />
  },
  {
    url: 'https://novonordisk.sharepoint.com/sites/DevX/SitePages/Developer-platform.aspx',
    label: 'Developer Portal Concept',
    icon: <WebIcon sx={{ color: theme.palette.primary.main }} />
  },
  {
    url: 'https://status.heimdall.novonordisk.cloud/',
    label: 'Heimdall Status Page',
    icon: <CloudIcon sx={{ color: theme.palette.primary.main }} />
  },
  {
    url: 'https://midgard.heimdall.novonordisk.cloud/docs/default/component/product-engineering-docs/',
    label: 'Product Engineering Guide',
    icon: <EngineeringIcon sx={{ color: theme.palette.primary.main }} />
  },
];

export const novoNordiskLinks = (theme: Theme) => [
  {
    url: 'https://novoaccess.corp.novocorp.net/identityiq/accessRequest/accessRequest.jsf#/accessRequest',
    label: 'novoAccess',
    icon: <LockIcon sx={{ color: theme.palette.primary.main }} />
  },
  {
    url: 'https://chatgpt.novo-genai.com/',
    label: 'Novo Nordisk ChatGPT',
    icon: <ChatIcon sx={{ color: theme.palette.primary.main }} />
  },
  {
    url: 'https://novonordisk.service-now.com/it',
    label: 'ServiceNow',
    icon: <BuildIcon sx={{ color: theme.palette.primary.main }} />
  },
  {
    url: 'https://novonordisk.service-now.com/hrportal?id=hr_index',
    label: 'askHR',
    icon: <HelpIcon sx={{ color: theme.palette.primary.main }} />
  },
  {
    url: 'https://novonordisk.sharepoint.com/sites/ISOtrain',
    label: 'ISOTrain',
    icon: <SchoolIcon sx={{ color: theme.palette.primary.main }} />
  },
  {
    url: 'https://novonordisk.sharepoint.com/sites/Abbreviations',
    label: 'Novo Nordisk Acronyms',
    icon: <TextFieldsIcon sx={{ color: theme.palette.primary.main }} />,
  },
];

export const infoBoxButtonsData = [
  {
    id: 1,
    text: 'register your repository',
    href: '../create/templates/default/developer-portal-onboarding',
  },
  {
    id: 2,
    text: 'view documentation',
    href: '../docs/default/component/dev-docs/developer-guides/onboarding-to-developer-portal/quick-onboard-to-software-catalog/',
  },
];

export const infoBoxBulletpoints = [
  {
    id: 1,
    text: 'Projects Discoverability:',
    button: {
      href: '../catalog',
      label: 'Software Catalog',
    },
  },
  {
    id: 2,
    text: 'Technical Documentation:',
    button: {
      href: '../docs',
      label: 'TechDocs',
    },
  },
  {
    id: 3,
    text: 'Reuse and Good Practices:',
    button: {
      href: '../create',
      label: 'Software Templates (Golden Paths)',
    },
  },
];

export const innersourceGithubLink = "https://github.com/innersource-nn";
export const leaveFeedbackLink = "https://dev.azure.com/novonordiskit/SEP/_workitems/create/Improvement%20Item?templateId=ed7b2e83-98ef-42ae-ba14-7909aef51e12&ownerId=03312cac-8157-4503-a808-7c4350e98712"
export const reportIssueLink = "https://github.com/innersource-nn/midgard/issues"
