import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import {
  ListIssuesOptions,
  ListPullRequestsOptions,
  ListReleaseOptions,
  ListReposOptions,
  OctokitApi,
  OctokitClientOptions,
  SearchIssuesAndPullRequestsOptions,
} from '../types';

export class OctokitClient implements OctokitApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor({ discoveryApi, fetchApi }: OctokitClientOptions) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  private async getBaseUrl(): Promise<string> {
    const baseUrl = await this.discoveryApi.getBaseUrl('octokit-wrapper-backend');
    return baseUrl;
  }

  async listIssues(options: ListIssuesOptions) {
    const baseUrl = await this.getBaseUrl();
    const params = new URLSearchParams(
      Object.entries(options).map(([key, value]) => [key, String(value)])
    );
    const response = await this.fetchApi.fetch(
      `${baseUrl}/issues?${params.toString()}`
    );
    return await response.json();
  }

  async listPullRequests(options: ListPullRequestsOptions) {
    const baseUrl = await this.getBaseUrl();
    const params = new URLSearchParams(
      Object.entries(options).map(([key, value]) => [key, String(value)])
    );
    const response = await this.fetchApi.fetch(
      `${baseUrl}/pull-requests?${params.toString()}`
    );
    return await response.json();
  }

  async listRepos(options: ListReposOptions) {
    const baseUrl = await this.getBaseUrl();
    const params = new URLSearchParams(
      Object.entries(options).map(([key, value]) => [key, String(value)])
    );
    const response = await this.fetchApi.fetch(
      `${baseUrl}/repos?${params.toString()}`
    );
    return await response.json();
  }

  async searchIssuesAndPullRequests(options: SearchIssuesAndPullRequestsOptions) {
    const baseUrl = await this.getBaseUrl();
    const params = new URLSearchParams(
      Object.entries(options).map(([key, value]) => [key, String(value)])
    );
    const response = await this.fetchApi.fetch(
      `${baseUrl}/search/issues?${params.toString()}`
    );
    return await response.json();
  }

  async listReleases(options: ListReleaseOptions) {
    const baseUrl = await this.getBaseUrl();
    const params = new URLSearchParams(
      Object.entries(options).map(([key, value]) => [key, String(value)])
    );
    const response = await this.fetchApi.fetch(
      `${baseUrl}/releases?${params.toString()}`
    );
    return await response.json();
  }
}
