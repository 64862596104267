import React from "react";

import { FeatureFlagged } from "@backstage/core-app-api";
import {
  EntityAboutCard,
  EntitySwitch,
  EntityLinksCard,
  EntityHasSubcomponentsCard
} from "@backstage/plugin-catalog";
import { EntityCatalogGraphCard } from "@backstage/plugin-catalog-graph";

import { Grid } from "@mui/material";
import {
  isGithubInsightsAvailable,
  EntityGithubInsightsReadmeCard,
  EntityGithubInsightsLanguagesCard,
  EntityGithubInsightsReleasesCard,
  EntityGithubInsightsContributorsCard,
  EntityGithubInsightsEnvironmentsCard,
  EntityGithubInsightsComplianceCard
} from "@roadiehq/backstage-plugin-github-insights";
import {
  isGithubPullRequestsAvailable,
  EntityGithubPullRequestsOverviewCard
} from "@roadiehq/backstage-plugin-github-pull-requests";

import { entityWarningContent } from "./EntityWarningContent";
import { novoAccessEntityContent } from "./NovoAccessEntityContent";
import { linguistEntityContent } from "./LinguistEntityContent";
import { FeatureFlagValue } from "../../../../utils/featureFlags";


export const overviewContent = (
  <Grid container spacing={3}>
    {entityWarningContent}
    <Grid container item xs={12}>
      <Grid container item md={6} xs={12} alignContent="flex-start">
        <Grid item xs={12}>
          <EntityAboutCard />
        </Grid>
        <FeatureFlagged with={FeatureFlagValue.SHOW_GITHUB_INSIGHTS}>
          <EntitySwitch>
            <EntitySwitch.Case if={isGithubInsightsAvailable}>
              <Grid item xs={12}>
                <EntityGithubInsightsReadmeCard maxHeight={500} />
              </Grid>
            </EntitySwitch.Case>
          </EntitySwitch>
        </FeatureFlagged>
        <Grid item xs={12}>
          <EntityLinksCard />
        </Grid>
        <FeatureFlagged with={FeatureFlagValue.SHOW_NOVOACCESS_CARD}>
          {novoAccessEntityContent}
        </FeatureFlagged>
        <Grid item xs={12}>
          <EntityHasSubcomponentsCard />
        </Grid>
      </Grid>
      <Grid container item md={6} xs={12} alignContent="flex-start">
        <Grid item xs={12}>
          <EntityCatalogGraphCard height={400} />
        </Grid>
        <FeatureFlagged with={FeatureFlagValue.SHOW_GITHUB_PR}>
          <EntitySwitch>
            <EntitySwitch.Case if={isGithubPullRequestsAvailable}>
              <Grid item xs={12}>
                <EntityGithubPullRequestsOverviewCard />
              </Grid>
            </EntitySwitch.Case>
          </EntitySwitch>
        </FeatureFlagged>
        <FeatureFlagged with={FeatureFlagValue.SHOW_GITHUB_INSIGHTS}>
          <EntitySwitch>
            <EntitySwitch.Case if={isGithubInsightsAvailable}>
              <Grid item xs={12}>
                <EntityGithubInsightsLanguagesCard />
                <EntityGithubInsightsReleasesCard />
                <EntityGithubInsightsContributorsCard />
                <EntityGithubInsightsEnvironmentsCard />
                <EntityGithubInsightsComplianceCard />
              </Grid>
            </EntitySwitch.Case>
          </EntitySwitch>
        </FeatureFlagged>
        {linguistEntityContent}
      </Grid>
    </Grid>
  </Grid>
);
