import React, { useState, useEffect } from "react";

import { configApiRef, useApi } from "@backstage/core-plugin-api";
import { useOctokitData, HookOptionsType } from "@internal/backstage-plugin-octokit-wrapper";

import { Button, CardContent, CardActions, Grid, Box, CircularProgress } from "@mui/material";
import GitHubIcon from '@mui/icons-material/GitHub';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { CardListItem, CardListItemDate, CardListItemName, CardSubtitle } from "../styled";
import { GithubPullRequestOrIssue } from '../../../types';
import { innersourceGithubLink } from "../homePageData";
import { InfoCard } from "@backstage/core-components";

export const EngageCard = () => {
  const [openIssues, setOpenIssues] = useState<GithubPullRequestOrIssue[]>([]);
  const configApi = useApi(configApiRef);
  const owner = 'innersource-nn';
  const type = HookOptionsType.Search;
  const perPage = 8;

  const { data, error, loading } = useOctokitData({
    owner,
    configApi,
    type,
    perPage,
    searchQuery: "org:innersource-nn+is:open+type:issue"
  });

  useEffect(() => {
    if (loading || error || !data) {
      return;
    }

    const openIssues: GithubPullRequestOrIssue[] =
      data?.map((issue) => ({
        id: issue.id,
        title: issue.title,
        htmlUrl: issue.html_url,
        createdAt: issue.created_at,
        user: {
          login: issue.user?.login || 'N/A',
        },
      })) || [];

    setOpenIssues(openIssues);
  }, [loading, data]);

  return (
    <InfoCard title="Engage in Inner-Source-NN">
      <CardContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <CardSubtitle>recently created <b>issues</b> in all repositories</CardSubtitle>
          </Grid>
          {loading ?
            (
              <Box display="flex" justifyContent="center" width="100%" p={2}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {!!openIssues && openIssues.length > 0 ? (
                  openIssues.map((issue) => (
                    <Grid item key={issue.id}>
                      <CardListItem>
                        <CardListItemDate>{new Date(issue.createdAt).toLocaleDateString()}</CardListItemDate>
                        <CardListItemName>"{issue.title}" by: <b>{issue.user.login}</b></CardListItemName>
                        <Button
                          component="a"
                          href={issue.htmlUrl}
                          target="_blank"
                          rel="noopener"
                          style={{ minWidth: 'fit-content' }}
                        >
                          <OpenInNewIcon fontSize="small" />
                        </Button>
                      </CardListItem>
                    </Grid>
                  ))
                ) : (
                  <Grid item>
                    <CardListItem>No open issues found</CardListItem>
                  </Grid>
                )}
              </>
            )
          }
        </Grid>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            component="a"
            target="_blank"
            rel="noopener"
            href={innersourceGithubLink}
            startIcon={<GitHubIcon />}
            fullWidth
          >
            <b>novo nordisk innersource</b>
          </Button>
        </Box>
      </CardActions>
    </InfoCard>
  );
};
