import React from 'react';

import { Content, Header, LinkButton, Page } from '@backstage/core-components';

import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import CategoryIcon from '@mui/icons-material/Category';
import ViewListIcon from '@mui/icons-material/ViewList';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FeedbackIcon from '@mui/icons-material/Feedback';
import GitHubIcon from '@mui/icons-material/GitHub';
import PeopleIcon from '@mui/icons-material/People';
import SupportIcon from '@mui/icons-material/Support';
import ThumbUpIcon from '@mui/icons-material/ThumbUpOffAlt';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import BuildIcon from '@mui/icons-material/Build';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

import { faqData } from './faqData';

const iconMap = {
  Home: HomeIcon,
  Info: InfoIcon,
  Catalog: CategoryIcon,
  Template: ViewListIcon,
  Docs: LibraryBooksIcon,
  Start: PlayArrowIcon,
  Feedback: FeedbackIcon,
  GitHub: GitHubIcon,
  Teams: PeopleIcon,
  Support: SupportIcon,
  Build: BuildIcon,
  ThumbUp: ThumbUpIcon,
  Lightbulb: LightbulbIcon,
};

export const FaqPage = () => (
  <Page themeId="documentation">
    <Header title="Frequently Asked Questions" />
    <Content>
      <Box py={4} px={15}>
        {faqData.map((faq) => {
          const QuestionIcon = iconMap[faq.icon] || HelpOutlineIcon;
          return (
            <Accordion key={faq.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                matomo-track="true"
                matomo-category="FAQ"
                matomo-action="FAQAccordionOpen"
                matomo-name={faq.question}
              >
                <Box py={1} display="flex" alignItems="center">
                  <QuestionIcon />
                  <Typography variant="h6" px={2}>{faq.question}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography px={5} pb={1}>{faq.answer}</Typography>
                {faq.links && faq.links.length > 0 && (
                  <>
                    <Typography px={5} py={2} variant="subtitle1">
                      <b>Check for more:</b>
                    </Typography>
                    <Box px={5} pb={1} display="flex" flexWrap="wrap" gap={1}>
                      {faq.links.map((link, index) => {
                        const LinkIcon = iconMap[link.icon];
                        return (
                          <Box key={index} display="flex" alignItems="center">
                            <LinkButton
                              to={link.url}
                              target="_blank"
                              rel="noopener"
                              variant="outlined"
                              startIcon={<LinkIcon />}
                              matomo-track="true"
                              matomo-category="FAQ"
                              matomo-action="FAQLinkButtonClick"
                              matomo-name={link.text}
                            >
                              {link.text}
                            </LinkButton>
                          </Box>
                        );
                      })}
                    </Box>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Content>
  </Page>
);
