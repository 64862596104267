import React from 'react';

import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import {
  HomePageStarredEntities,
  HomePageToolkit,
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import { SearchContextProvider } from '@backstage/plugin-search-react';

import {
  HomePageContainer,
  HomePageHeadingImage,
  HomePageHeadingTitle,
} from './styled';
import { developerPortalLinks, novoNordiskLinks } from './homePageData';
import { DiscoverCard } from './cards/DiscoverCard';
import { CollaborateCard } from './cards/CollaborateCard';
import { EngageCard } from './cards/EngageCard';
import { CustomSearchBar } from '../../shared/CustomSearchBar';
import NNLogoBlue from "../../assets/nn__logo--blue.png";
import NNLogoWhite from "../../assets/nn__logo--white.png";
import HomePageInfoBox from './HomePageInfoBox';

export const HomePage = () => {
  const theme = useTheme();
  const logoType = theme.palette.mode === 'dark' ? NNLogoWhite : NNLogoBlue;

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <HomePageContainer container justifyContent="center">
            <Grid container item xs={12} justifyContent='center'>
              <HomePageHeadingImage src={logoType} alt='Novo Nordisk logo' height={80} />
              <HomePageHeadingTitle>Developer Portal</HomePageHeadingTitle>
            </Grid>
            <Grid container item md={8} xs={12} m={2} justifyContent='center'>
              <CustomSearchBar />
            </Grid>
            <Grid container item xs={12} mt={2} justifyContent="center">
              <HomePageInfoBox />
            </Grid>
            <Grid container mt={1}>
              <Grid item md={6} xs={12}>
                <HomePageToolkit
                  title="Developer Experience Links"
                  tools={developerPortalLinks(theme)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <HomePageToolkit
                  title="Novo Nordisk Links"
                  tools={novoNordiskLinks(theme)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={4} xs={12}>
                <Grid mt={3}>
                  <DiscoverCard />
                </Grid>
                <Grid mt={3}>
                  <HomePageStarredEntities groupByKind />
                </Grid>
              </Grid>
              <Grid item lg={4} xs={12} mt={3}>
                <CollaborateCard />
              </Grid>
              <Grid item lg={4} xs={12} mt={3}>
                <EngageCard />
              </Grid>
            </Grid>
          </HomePageContainer>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
