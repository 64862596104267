import React from 'react';
import { Route } from 'react-router-dom';

import { ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
} from '@backstage/plugin-catalog';
import { CatalogImportPage } from '@backstage/plugin-catalog-import';
import { ScaffolderPage } from '@backstage/plugin-scaffolder';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ExpandableNavigation, LightBox, ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import { DiscoveryPluginPage } from '@internal/backstage-plugin-discovery-plugin';
import { MidgardOnboard } from '@mexl/plugin-envinfo';

import { HomePage } from './components/home/HomePage';
import { FaqPage } from './components/faq/FaqPage';
import { entityPage } from './components/catalog/entity/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { SettingsPage } from './components/settings/SettingsPage';
import { CustomCatalogPage } from './components/catalog/CustomCatalogPage/catalog/CustomCatalogPage'
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';

export const routes = (
  <FlatRoutes>
    {/* SIDEBAR NAV START */}
    <Route
      path="/"
      element={<HomePage />}
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route
      path="/catalog"
      element={<CatalogIndexPage />}
    >
      <CustomCatalogPage
        key="catalog"
        pagination
      />
    </Route>
    <Route
      path="/software-templates"
      element={<CatalogIndexPage />}
    >
      <CustomCatalogPage
        initialKind="template"
        key="software-templates"
        pageTitleSuffix=" - Templates"
        hideKindFilter
        pagination
      />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
        <LightBox />
        <ExpandableNavigation />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          groups={[
            {
              title: "Golden Paths",
              filter: entity =>
                entity?.metadata?.annotations?.['backstage.io/source-location']
                  .includes('innersource-nn/templates') ?? false,
            },
          ]}
        />
      }
    />
    <Route
      path="/api-docs"
      element={<ApiExplorerPage />}
    >
      <CustomCatalogPage
        initialKind="api"
        key="api-docs"
        pageTitleSuffix=" - APIs"
        hideKindFilter
        pagination
      />
    </Route>
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route
      path="/docs"
      element={<TechDocsIndexPage />}
    />
    <Route path="/faq" element={<FaqPage />} />
    <Route
      path="/settings"
      element={<SettingsPage />}
    />
    {/* SIDEBAR NAV END */}

    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route
      path="/catalog-graph"
      element={<CatalogGraphPage />}
    />
    <Route
      path="/midgard-onboard/:url"
      element={<MidgardOnboard />}
    />
    <Route
      path="/devtools"
      element={<DevToolsPage />}
    >
      {customDevToolsPage}
    </Route>
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />;
    <Route path="/discovery" element={<DiscoveryPluginPage />} />
  </FlatRoutes>
);
