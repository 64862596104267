import { useReducer, useEffect } from 'react';
import { useApi, discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';

import { OctokitClient } from '../api';
import { HookOptions, HookOptionsType } from '../types';
import {
  fetchIssues,
  fetchPullRequests,
  fetchRepos,
  fetchSearch,
  fetchReleases,
  dataFetchReducer
} from '../services/dataFetchFunctions';

export const useOctokitData = (options: HookOptions) => {
  const { owner, repo, configApi, type, searchQuery, perPage } = options;
  const [state, dispatch] = useReducer(dataFetchReducer, {
    data: null,
    loading: true,
    error: null,
  });
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const client = new OctokitClient({ discoveryApi, fetchApi });

  useEffect(() => {
    switch (type) {
      case HookOptionsType.Issues:
        fetchIssues(options, client, dispatch);
        break;
      case HookOptionsType.PullRequests:
        fetchPullRequests(options, client, dispatch);
        break;
      case HookOptionsType.Repos:
        fetchRepos(options, client, dispatch);
        break;
      case HookOptionsType.Search:
        fetchSearch(options, client, dispatch);
        break;
      case HookOptionsType.Releases:
        fetchReleases(options, client, dispatch);
        break;
      default:
        throw new Error('Invalid type specified');
    }
  }, [configApi, owner, type, repo, searchQuery, perPage]);

  return { ...state };
};
