import { FeatureFlag } from "@backstage/core-plugin-api";

export enum FeatureFlagValue {
  SHOW_NOVOACCESS_CARD = 'show-novoaccess-card',
  SHOW_GITHUB_PR = 'github-pr',
  SHOW_GITHUB_INSIGHTS = 'show-github-insights',
}

export const featureFlags: (FeatureFlag & Omit<FeatureFlag, 'pluginId'>)[] = [
  {
    pluginId: '',
    name: FeatureFlagValue.SHOW_NOVOACCESS_CARD,
    description: 'Shows NovoAccess card on the Entity Page',
  },
  {
    pluginId: '',
    name: FeatureFlagValue.SHOW_GITHUB_PR,
    description: 'Shows Github PR card on the Entity Page',
  },
  {
    pluginId: '',
    name: FeatureFlagValue.SHOW_GITHUB_INSIGHTS,
    description: 'Shows Github Insights on the Entity Page',
  }
]
