export const faqData = [
  {
    id: 1,
    question: "What is the Novo Nordisk Developer Portal?",
    answer: "The Novo Nordisk Developer Portal is a comprehensive platform that centralizes all resources, tools, and services required by Novo Nordisk developers. It offers a unified interface for accessing documentation, managing projects, and fostering team collaboration.",
    icon: "Home",
    links: []
  },
  {
    id: 2,
    question: "Why are we using Backstage for our Developer Portal?",
    answer: "Backstage helps unify our tools and information in one place, making it easy to find everything. It offers a customizable framework that integrates with our existing tools.",
    icon: "Info",
    links: [
      {
        text: "Backstage homepage",
        url: "https://backstage.io",
        icon: "Home"
      },
      {
        text: "Backstage Benefits",
        url: "https://backstage.io/docs/overview/what-is-backstage",
        icon: "Info"
      }
    ]
  },
  {
    id: 3,
    question: "What are the main features of the Developer Portal?",
    answer: "Software catalog, documentation, project management, software templates, and various plugins. These features provide a comprehensive development environment.",
    icon: "Lightbulb",
    links: [
      {
        text: "Software Catalog",
        url: "../catalog",
        icon: "Catalog"
      },
      {
        text: "Software Templates",
        url: "../software-templates",
        icon: "Template"
      },
      {
        text: "TechDocs",
        url: "../docs",
        icon: "Docs"
      }
    ]
  },
  {
    id: 4,
    question: "What can I do on the Developer Portal?",
    answer: "Access documentation, manage projects, register software components, and use templates for development tools.",
    icon: "Build",
    links: [
      {
        text: "Get started: Software Catalog & TechDocs",
        url: "../docs/default/Component/dev-docs/developer-guides/onboarding-to-developer-portal/quick-onboard-to-software-catalog/",
        icon: "Start"
      },
      {
        text: "Get started: Software Templates",
        url: "../docs/default/Component/dev-docs/developer-guides/onboarding-to-developer-portal/quick-onboard-to-software-templates/",
        icon: "Start"
      }
    ]
  },
  {
    id: 5,
    question: "Who should use the Developer Portal?",
    answer: "The portal is for everyone involved in software development at Novo Nordisk, including developers, scientists, managers, and more.",
    icon: "Help",
    links: []
  },
  {
    id: 6,
    question: "How does the Developer Portal make my work easier?",
    answer: "It centralizes resources to save time and integrates with other tools for smoother workflows. The portal reduces the complexity of managing multiple tools and ensures efficient workflows.",
    icon: "Support",
    links: []
  },
  {
    id: 7,
    question: "How can I help improve the Developer Portal?",
    answer: "Contribute to documentation, build plugins, report issues, and provide feedback. Your contributions help us create a more efficient and user-friendly portal.",
    icon: "ThumbUp",
    links: [
      {
        text: "Suggestions & Feedback",
        url: "https://dev.azure.com/novonordiskit/SEP/_workitems/create/Improvement%20Item?templateId=ed7b2e83-98ef-42ae-ba14-7909aef51e12&ownerId=03312cac-8157-4503-a808-7c4350e98712",
        icon: "Feedback"
      }
    ]
  },
  {
    id: 8,
    question: "What kind of support is available for the Developer Portal?",
    answer: "Support is available through our Teams channel, GitHub issues, and detailed documentation. Our support channels address any issues you may encounter and provide guidance.",
    icon: "Support",
    links: [
      {
        text: "GitHub Issues",
        url: "https://github.com/innersource-nn/midgard/issues",
        icon: "GitHub"
      },
      {
        text: "Teams Channel",
        url: "https://teams.microsoft.com/l/channel/19%3A6f54208b3cbe4794967dbe46ef0e3e8c%40thread.tacv2/Developer%20Portal%20-%20Midgard?groupId=eab6f9f1-87d0-45d4-b29b-cc5941ac0cfb",
        icon: "Teams"
      }
    ]
  }
];
