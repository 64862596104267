import { ConfigApi, DiscoveryApi, FetchApi } from "@backstage/core-plugin-api";
import { RestEndpointMethodTypes } from "@octokit/rest";

import { OctokitClient } from "./api/OctokitApi";

export type OctokitClientOptions = {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi
};

export type ListIssuesOptions = RestEndpointMethodTypes["issues"]["listForRepo"]["parameters"];
export type ListIssuesResult = Promise<RestEndpointMethodTypes["issues"]["listForRepo"]["response"]["data"]>;

export type ListPullRequestsOptions = RestEndpointMethodTypes["pulls"]["list"]["parameters"];
export type ListPullRequestsResult = Promise<RestEndpointMethodTypes["pulls"]["list"]["response"]["data"]>;

export type ListReposOptions = RestEndpointMethodTypes["repos"]["listForUser"]["parameters"]
export type ListReposResult = Promise<RestEndpointMethodTypes["repos"]["listForUser"]["response"]["data"]>

export type ListReleaseOptions = RestEndpointMethodTypes["repos"]["listReleases"]["parameters"];
export type ListReleasesResult = Promise<RestEndpointMethodTypes["repos"]["listReleases"]["response"]["data"]>;

export type SearchIssuesAndPullRequestsOptions = RestEndpointMethodTypes["search"]["issuesAndPullRequests"]["parameters"];
export type SearchIssuesAndPullRequestsResult = Promise<RestEndpointMethodTypes["search"]["issuesAndPullRequests"]["response"]["data"]["items"]>;


export interface OctokitApi {
  listIssues(options: ListIssuesOptions): ListIssuesResult;
  listPullRequests(options: ListPullRequestsOptions): ListPullRequestsResult;
  listRepos(options: ListReposOptions): ListReposResult;
  listReleases(options: ListReleaseOptions): ListReleasesResult;
  searchIssuesAndPullRequests(options: SearchIssuesAndPullRequestsOptions): SearchIssuesAndPullRequestsResult;
}

export type HookOptions = {
  configApi: ConfigApi;
  owner: string;
  repo?: string;
  type: HookOptionsType;
  searchQuery?: string;
  perPage?: number;
};

export enum HookOptionsType {
  Issues = 'issues',
  PullRequests = 'pullRequests',
  Repos = 'repos',
  Search = 'search',
  Releases = 'releases',
}

export type DataType =
  | Awaited<ReturnType<typeof OctokitClient.prototype.listIssues>>
  | Awaited<ReturnType<typeof OctokitClient.prototype.listPullRequests>>
  | Awaited<ReturnType<typeof OctokitClient.prototype.listRepos>>
  | Awaited<ReturnType<typeof OctokitClient.prototype.searchIssuesAndPullRequests>>
  | Awaited<ReturnType<typeof OctokitClient.prototype.listReleases>>;

export type State = {
  data: DataType | null;
  loading: boolean;
  error: Error | null;
};

export type Action =
  | { type: ActionType.FetchInit }
  | { type: ActionType.FetchSuccess; payload: DataType }
  | { type: ActionType.FetchFailure; payload: Error };

export enum ActionType {
  FetchInit = 'FETCH_INIT',
  FetchSuccess = 'FETCH_SUCCESS',
  FetchFailure = 'FetchFailure',
}
