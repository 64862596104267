import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { InputAdornment, TextField, IconButton, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';

import { useSearch } from '@backstage/plugin-search-react';

const CustomSearchBarWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  '& .searchBarInput': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px !important',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  '& .searchBarOutline': {
    border: 'none',
  }
}));

export const CustomSearchBar = () => {
  const { setTerm } = useSearch();
  const navigate = useNavigate();
  const [localTerm, setLocalTerm] = useState('');

  const handleSearch = () => {
    if (localTerm.trim()) {
      setTerm(localTerm);
      navigate(`/search?query=${encodeURIComponent(localTerm)}`);
    }
  };

  const handleClear = () => {
    setLocalTerm('');
    setTerm('');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <CustomSearchBarWrapper>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search"
        value={localTerm}
        onChange={(e) => setLocalTerm(e.target.value)}
        onKeyDown={handleKeyDown}
        InputProps={{
          classes: {
            root: 'searchBarInput',
            notchedOutline: 'searchBarOutline',
          },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                onClick={handleSearch}
                disabled={!localTerm.trim()}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Button
                onClick={handleSearch}
                disabled={!localTerm.trim()}
              >
                search
              </Button>
              <Button
                onClick={handleClear}
                disabled={!localTerm.trim()}
              >
                clear
              </Button>
            </InputAdornment>
          ),
        }}
      />
    </CustomSearchBarWrapper>
  );
};
